import React from "react";
import ResultShimmerCard from "./ResultShimmerCard";

const Shimmer = () => {
  return (
    <div className="flex flex-wrap w-full justify-center mt-20 ">
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
      <ResultShimmerCard />
    </div>
  );
};

export default Shimmer;
