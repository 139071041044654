import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast , ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import NotificationCard from './NotificationCard';

const ForgotPassword = () => {
  const origin = process.env.REACT_APP_BACKEND_ORIGIN;

  const [inputs, setInputs] = useState({ email: "" });
  const [showNotification, setShowNotification] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };


  const handleSend = async () => {
    try {
      // if(countdown>0)return ;
      if(!inputs.email){
        toast.error("Please enter your email address");
        return;
      }

      toast.success(`Sending Email`);
      const response = await axios.post(`${origin}/api/v1/users/forgotPassword`, inputs);
      toast.success(`${response.data.message}`);
      setShowNotification(true);
      // setInputs({ email: "" });
      // startCountdown(60);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // handleClick();
      handleSend();
    }
  };



  return (
    <>
     {showNotification && (
        <NotificationCard  
          message="User Verification Link for Forgot Password has been sent to email below!"
          email={inputs.email} 
          // onClose={handleClose} 
          onResend={handleSend}
        />
      )}
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="w-4/5 md:w-1/2 mx-auto justify-center bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
            <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
              Forgot Password?
            </h2>

            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={inputs.email}
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                onKeyDown={handleKeyDown}
                
                // disabled={countdown > 0}
              />
            </div>
            <button
              onClick={handleSend}
              className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              // disabled={countdown > 0}
            >
              {/* {countdown > 0 ? `Resend Link ${countdown} seconds` : 'Request Password Reset Email'} */}
              {`Request Password Reset Email`}

            </button>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
 
export default ForgotPassword;
