import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// Define a list of colors for tracking event types
const COLORS = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#FF9F40",
  "#E7E9ED",
  "#8A2BE2",
  "#00FA9A",
  "#FF1493",
];

const Wallet = () => {
  const FILTER_OPTIONS = ["All", "Week", "Month", "Year"];

  const [filter, setFilter] = useState("All");
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const origin =
    process.env.REACT_APP_BACKEND_ORIGIN || "http://localhost:5000";
  axios.defaults.withCredentials = true;

  useEffect(() => {
    getAnalytics();
  }, [filter]);

  const getAnalytics = async () => {
    setLoading(true);
    setError(null);
    try {
      const filterParam = filter.toLowerCase();
      const response = await axios.get(
        `${origin}/api/v1/analytics/user-activity?filter=${filterParam}`
      );

      if (response.data.status === "success") {
        setAnalyticsData(response.data.data);
      } else {
        setError("Failed to fetch analytics data.");
      }
    } catch (err) {
      console.error(err);
      setError(
        err.response?.data?.message || "An error occurred while fetching data."
      );
    } finally {
      setLoading(false);
    }
  };

  const getColor = (index) => COLORS[index % COLORS.length];

  const processTrackingData = () => {
    if (!analyticsData || !analyticsData.tracking)
      return { labels: [], datasets: [] };

    const tracking = analyticsData.tracking;

    // Extract unique event types
    const eventTypes = Object.keys(tracking);
    const colorMap = {};

    // Assign a unique color to each event type
    eventTypes.forEach((type, index) => {
      colorMap[type] = getColor(index);
    });

    // Extract all unique dates across all event types
    const allDatesSet = new Set();
    eventTypes.forEach((type) => {
      Object.keys(tracking[type]).forEach((date) => allDatesSet.add(date));
    });
    const allDates = Array.from(allDatesSet).sort();

    // Create datasets for each event type
    const datasets = eventTypes.map((type) => {
      const data = allDates.map((date) => tracking[type][date] || 0);
      return {
        label: type,
        data,
        backgroundColor: colorMap[type],
        borderRadius: 5,
      };
    });

    return { labels: allDates, datasets };
  };

  // Function to process searches or clicks data into Chart.js format
  const processSimpleData = (dataObject) => {
    if (!dataObject) return { labels: [], data: [] };

    const labels = Object.keys(dataObject).sort();
    const data = labels.map((date) => dataObject[date]);

    return { labels, data };
  };

  // Prepare chart data for searches
  const searchesChartData = analyticsData
    ? {
        labels: Object.keys(analyticsData.searches).sort(),
        datasets: [
          {
            label: "Searches",
            data: Object.keys(analyticsData.searches)
              .sort()
              .map((date) => analyticsData.searches[date]),
            backgroundColor: "#10B981", // Teal
            borderRadius: 10,
          },
        ],
      }
    : { labels: [], datasets: [] };

  // Prepare chart data for clicks
  const clicksChartData = analyticsData
    ? {
        labels: Object.keys(analyticsData.clicks).sort(),
        datasets: [
          {
            label: "Clicks",
            data: Object.keys(analyticsData.clicks)
              .sort()
              .map((date) => analyticsData.clicks[date]),
            backgroundColor: "#3B82F6", // Blue
            borderRadius: 10,
          },
        ],
      }
    : { labels: [], datasets: [] };

  // Prepare chart data for tracking
  const trackingDataProcessed = processTrackingData();
  const trackingChartData = {
    labels: trackingDataProcessed.labels,
    datasets: trackingDataProcessed.datasets,
  };

  // Chart options (can be customized as needed)
  const commonChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="max-w-6xl mx-auto p-4 mt-14 bg-white shadow-lg rounded-lg">
      <div className="flex justify-center mb-6 space-x-4">
        {FILTER_OPTIONS.map((option) => (
          <button
            key={option}
            onClick={() => setFilter(option)}
            className={`px-4 py-2 rounded ${
              filter === option
                ? "bg-teal-500 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            } transition duration-200`}
          >
            {option}
          </button>
        ))}
      </div>

      {/* Loading State */}
      {loading && (
        <div className="text-center">
          <p className="text-gray-600">Loading analytics data...</p>
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="text-center">
          <p className="text-red-500">{error}</p>
        </div>
      )}

      {/* Display Charts if data is available */}
      {!loading && !error && analyticsData && (
        <div className="space-y-12">
          {/* Searches Chart */}
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-4">Searches Overview</h2>
            {searchesChartData.labels.length > 0 ? (
              <Bar data={searchesChartData} options={commonChartOptions} />
            ) : (
              <p className="text-gray-600">
                No search data available for the selected filter.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;
