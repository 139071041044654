import React , {useEffect, useState} from 'react';

const TimerButton = ({countdown})=>{
  return (
    <div className='text-white bg-red-500 border-0 mt-4 text-center py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg'>{`Resend email in ${countdown} seconds`}</div>
  )
}

const ResendButton = ({onResend , triggerResend ,resetTimer})=>{
  return (
    <div 
    onClick={()=>{ onResend(); resetTimer(60) ; triggerResend((prev)=>!prev);  }}
    className='text-white bg-green-500 border-0 mt-4 text-center py-2 px-8 focus:outline-none hover:bg-green-600 rounded text-lg'>Resend email</div>
  )
}

const NotificationCard = ({ message, email, onResend }) => {

  const [countdown, setCountdown] = useState(60);
  const [triggerResend , setTriggerResend]= useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(interval);
  }, [triggerResend]);




  



  return (
    <div className="z-10 fixed inset-0 flex items-center justify-center bg-white bg-opacity-100">
      <div className="bg-gray-100 rounded-lg shadow-lg p-6 w-96 relative">
        {/* <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          // onClick={onClose} 
        >
          &times;
        </button> */}
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Notification</h2>
        <p className="text-gray-600 mb-4">{message}</p>
        <p className="text-gray-500 text-sm">{email}</p>
        {
          countdown > 0 ? (<TimerButton countdown={countdown}/>) : ( <ResendButton onResend={onResend} triggerResend = {setTriggerResend} resetTimer = {setCountdown} />)
        }

      </div>
    </div>
  );
};

export default NotificationCard;
