import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Table, Space, Typography, Tag, Button } from "antd";
import {
  CheckCircleOutlined,
  SearchOutlined,
  LinkOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const TimelinePage = () => {
  const { email } = useParams();
  const [timelineData, setTimelineData] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [showTimeline, setShowTimeline] = useState(true); // Toggle between timeline and actions tables
  const origin = process.env.REACT_APP_BACKEND_ORIGIN;

  useEffect(() => {
    getTimelineData();
    getUserActions();
  }, [email]);

  // const getTimelineData = async () => {
  //   try {
  //     const response = await axios.get(`${origin}/api/v1/users/getUserSearchTimeline?email=${email}`);
  //     setTimelineData(response.data.data.timeline);
  //   } catch (error) {
  //     console.error("Error fetching timeline:", error);
  //   }
  // };

  const getTimelineData = async () => {
    try {
      const response = await axios.get(
        `${origin}/api/v1/users/getUserSearchTimeline?email=${email}`
      );
      setTimelineData(response.data.data.timeline);
    } catch (error) {
      console.error("Error fetching timeline:", error);
    }
  };

  const getBaseUrl = (url) => {
    try {
      return new URL(url).origin;
    } catch (error) {
      console.error("Invalid URL:", url);
      return "";
    }
  };

  const getUserActions = async () => {
    try {
      const response = await axios.get(
        `${origin}/api/v1/users/getUserActions?email=${email}`
      );
      setUserActions(
        Array.isArray(response.data.data.actions)
          ? response.data.data.actions
          : []
      );
    } catch (error) {
      console.error("Error fetching user actions:", error);
    }
  };

  // Render tracking data in nested table for each click
  const renderTrackingData = (trackingData) => (
    <Table
      columns={[
        {
          title: "Event Type",
          dataIndex: "eventType",
          key: "eventType",
          render: (eventType) => <Tag color="orange">{eventType}</Tag>,
        },
        {
          title: "Details",
          key: "eventDetails",
          render: (tracking) => (
            <>
              {tracking.eventType === "click" && (
                <Text>Clicked Text: {tracking.eventDetails.text}</Text>
              )}
              {tracking.eventType === "page_view" && (
                <div>
                  <Text>
                    Page View:{" "}
                    <a
                      href={tracking.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tracking.url}
                    </a>{" "}
                    <Text className="text-gray-500">
                      (Base URL: {getBaseUrl(tracking.url)})
                    </Text>
                  </Text>
                  <br />
                  <Text>
                    Referrer:{" "}
                    <a
                      href={tracking.referrer}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tracking.referrer}
                    </a>{" "}
                    <Text className="text-gray-500">
                      (Base URL: {getBaseUrl(tracking.referrer)})
                    </Text>
                  </Text>
                </div>
              )}
            </>
          ),
        },
        {
          title: "Timestamp",
          dataIndex: "timestamp",
          key: "timestamp",
          render: (timestamp) => new Date(timestamp).toLocaleString(),
        },
      ]}
      dataSource={trackingData}
      pagination={false}
      rowKey={(record) => record.timestamp}
    />
  );

  // Render clicks in nested table for each search entry
  const renderClickData = (clicks) => (
    <Table
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (title, record) => (
            <Space>
              <CheckCircleOutlined className="text-green-500" />
              <Text>{title}</Text>
            </Space>
          ),
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "Link",
          dataIndex: "link",
          key: "link",
          render: (link) => (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600"
            >
              <LinkOutlined />
            </a>
          ),
        },
      ]}
      dataSource={clicks}
      pagination={false}
      rowKey={(record) => record.title}
      expandable={{
        expandedRowRender: (record) => renderTrackingData(record.trackingData),
        rowExpandable: (record) => record.trackingData.length > 0,
      }}
    />
  );

  // Render user action data in nested table
  const renderActionsTable = (clickedItems) => (
    <Table
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (title) => (
            <Space>
              <CheckCircleOutlined className="text-green-500" />
              <Text>{title}</Text>
            </Space>
          ),
        },
        {
          title: "Link",
          dataIndex: "link",
          key: "link",
          render: (link) => (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600"
            >
              <LinkOutlined />
            </a>
          ),
        },
        {
          title: "Visited Product Page",
          key: "visitedProductPage",
          render: (record) => {
            console.log(record);
            return (
              <>
                <Space size="small">
                  <Text>
                    {record.actions[0]?.visitedProductPage.occurred
                      ? "✅"
                      : "❌"}
                  </Text>

                  {record.actions[0]?.visitedProductPage.occurred && (
                    <>
                      <a
                        href={record.actions[0]?.visitedProductPage.referrer}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600"
                      >
                        <LinkOutlined />
                      </a>
                      <Text>-></Text>
                      <a
                        href={record.actions[0]?.visitedProductPage.pageLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-600"
                      >
                        <LinkOutlined />
                      </a>
                    </>
                  )}
                </Space>
              </>
            );
          },
        },
        {
          title: "Configured Product",
          key: "configuredProduct",
          render: (record) => {
            return (
              <Space size="small">
                <Text>
                  {record.actions[0]?.configuredProduct.occurred ? "✅" : "❓"}
                </Text>

                {record.actions[0]?.configuredProduct.occurred && (
                  <>
                    <a
                      href={record.actions[0]?.configuredProduct.referrer}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      <LinkOutlined />
                    </a>
                    <Text>-></Text>
                    <a
                      href={record.actions[0]?.configuredProduct.pageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600"
                    >
                      <LinkOutlined />
                    </a>
                  </>
                )}
              </Space>
            );
          },
        },
        {
          title: "Added to Cart",
          key: "addedToCart",
          render: (record) => {
            return (
              <Space size="small">
                <Text>
                  {record.actions[0]?.addedToCart.occurred ? "✅" : "❌"}
                </Text>

                {record.actions[0]?.addedToCart.occurred && (
                  <>
                    <a
                      href={record.actions[0]?.addedToCart.referrer}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      <LinkOutlined />
                    </a>
                    <Text>-></Text>
                    <a
                      href={record.actions[0]?.addedToCart.pageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600"
                    >
                      <LinkOutlined />
                    </a>
                  </>
                )}
              </Space>
            );
          },
        },
        {
          title: "Viewed Cart",
          key: "viewedCart",
          render: (record) => {
            return (
              <Space size="small">
                <Text>
                  {record.actions[0]?.viewedCart.occurred ? "✅" : "❌"}
                </Text>

                {record.actions[0]?.viewedCart.occurred && (
                  <>
                    <a
                      href={record.actions[0]?.viewedCart.referrer}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      <LinkOutlined />
                    </a>
                    <Text>-></Text>
                    <a
                      href={record.actions[0]?.viewedCart.pageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600"
                    >
                      <LinkOutlined />
                    </a>
                  </>
                )}
              </Space>
            );
          },
        },
        {
          title: "Purchase Started",
          key: "purchased",
          render: (record) => {
            return (
              <Space size="small">
                <Text>
                  {record.actions[0]?.purchased.occurred ? "✅" : "❓"}
                </Text>

                {record.actions[0]?.purchased.occurred && (
                  <>
                    <a
                      href={record.actions[0]?.purchased.referrer}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      <LinkOutlined />
                    </a>
                    <Text>-></Text>
                    <a
                      href={record.actions[0]?.purchased.pageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600"
                    >
                      <LinkOutlined />
                    </a>
                  </>
                )}
              </Space>
            );
          },
        },
        {
          title: "Purchase Completed",
          key: "purchased",
          render: (record) => {
            return (
              <Space size="small">
                <Text>
                  {record.actions[0]?.purchased.occurred ? "✅" : "❓"}
                </Text>

                {record.actions[0]?.purchased.occurred && (
                  <>
                    <a
                      href={record.actions[0]?.purchased.referrer}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      <LinkOutlined />
                    </a>
                    <Text>-></Text>
                    <a
                      href={record.actions[0]?.purchased.pageLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600"
                    >
                      <LinkOutlined />
                    </a>
                  </>
                )}
              </Space>
            );
          },
        },
      ]}
      dataSource={Array.isArray(clickedItems) ? clickedItems : []}
      pagination={false}
      rowKey={(record) => record.title}
    />
  );

  // Columns for the top-level search data
  const columns = [
    {
      title: "Search Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (keyword) => (
        <Space>
          <SearchOutlined className="text-blue-500" />
          <Text>{keyword}</Text>
        </Space>
      ),
    },
    {
      title: "Search Times",
      dataIndex: "searchTimes",
      key: "searchTimes",
      render: (times) => (
        <div>
          {times.map((time, index) => (
            <Text key={index} className="block">
              {new Date(time).toLocaleString()}
            </Text>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-4">Timeline for {email}</h1>

      <div className="mb-4">
        <Button
          type="primary"
          onClick={() => setShowTimeline(true)}
          className="mr-2"
        >
          Show Timeline
        </Button>
        <Button type="primary" onClick={() => setShowTimeline(false)}>
          Show User Actions
        </Button>
      </div>

      <Table
        columns={[
          {
            title: "Search Keyword",
            dataIndex: ["searchData", "0", "keyword"],
            key: "keyword",
            render: (keyword) => (
              <Space>
                <SearchOutlined className="text-blue-500" />
                <Text>{keyword}</Text>
              </Space>
            ),
          },
        ]}
        dataSource={userActions}
        pagination={false}
        rowKey={(record) => record.searchData[0]?.keyword}
        expandable={{
          expandedRowRender: (record) =>
            renderActionsTable(record.searchData[0]?.clickedItems),
          rowExpandable: (record) =>
            Array.isArray(record.searchData[0]?.clickedItems) &&
            record.searchData[0].clickedItems.length > 0,
        }}
      />

      <Table
        columns={columns}
        dataSource={timelineData}
        pagination={false}
        rowKey={(record) => record.keyword}
        expandable={{
          expandedRowRender: (record) => renderClickData(record.clicks),
          rowExpandable: (record) => record.clicks.length > 0,
        }}
      />

      {/* User actions table */}
    </div>
  );
};

export default TimelinePage;
