import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setLoginStatus } from '../utils/logInStatusSlice';
import { Menu, Dropdown, Button, Space, Typography } from 'antd';
import { MenuOutlined, UserOutlined, LogoutOutlined, HomeOutlined } from '@ant-design/icons';

const { Text } = Typography;

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const origin = process.env.REACT_APP_BACKEND_ORIGIN;
    axios.defaults.withCredentials = true;

    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const isLoggedIn = useSelector(store => store.logInStatus.isLoggedIn);
    const role = useSelector(store => store.user.role);
    const name = useSelector(store => store.user.name);
    const email = useSelector(store => store.user.email);

    const handleLogout = async () => {
        try {
            const response = await axios.get(`${origin}/api/v1/users/logout`);
            await dispatch(setLoginStatus(false));
            navigateTo("/");

            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const menuItems = (
        <Menu onClick={toggleMenu}>
            <Menu.Item key="home" icon={<HomeOutlined />}>
                <Link to="/">Home</Link>
            </Menu.Item>
            {role === 'admin' && (
                <Menu.Item key="admin" icon={<UserOutlined />}>
                    <Link to="/allUsers">Admin Console</Link>
                </Menu.Item>
            )}
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    const guestMenuItems = (
        <Menu onClick={toggleMenu}>
            <Menu.Item key="login">
                <Link to="/logIn">Log In</Link>
            </Menu.Item>
            <Menu.Item key="signup">
                <Link to="/signUp">Sign Up</Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='bg-gray-200 sticky top-0 z-20'>
            <div className='flex justify-between items-center p-4'>
                <div>
                    {isLoggedIn && (
                        <div>
                            <Text strong>{name}</Text>
                            <Text type="secondary" className='ml-2'>{`(${role.charAt(0).toUpperCase() + role.slice(1)})`}</Text>
                            <div>{email}</div>
                        </div>
                    )}
                </div>

                <div className='md:hidden'>
                    <Dropdown 
                        overlay={isLoggedIn ? menuItems : guestMenuItems} 
                        trigger={['click']}
                        onVisibleChange={toggleMenu}
                        visible={isMenuOpen}
                    >
                        <Button icon={<MenuOutlined />} />
                    </Dropdown>
                </div>

                <div className='hidden md:flex gap-x-4'>
                    {isLoggedIn === false ? (
                        <Space>
                            <Link to="/logIn">
                                <Button type="primary">Log In</Button>
                            </Link>
                            <Link to="/signUp">
                                <Button type="primary">Sign Up</Button>
                            </Link>
                        </Space>
                    ) : (
                        <Space>
                            <Link to="/">
                                <Button icon={<HomeOutlined />}>Home</Button>
                            </Link>
                            {role === 'admin' && (
                                <Link to="/allUsers">
                                    <Button icon={<UserOutlined />}>Admin Console</Button>
                                </Link>
                            )}
                            <Button icon={<LogoutOutlined />} onClick={handleLogout}>
                                Log Out
                            </Button>
                        </Space>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NavBar;

