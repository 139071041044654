import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import ResultsPage from './components/ResultsPage';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './utils/store';
import LogIn from './components/LogIn';
import SignUp from './components/SignUp';
import Body from './components/Body';
import UserTable from './components/UserTable';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import UserProfile from './components/UserProfile';
import Wallet from './components/Wallet';
import TimelinePage from './components/TimelinePage'; // Import the TimelinePage component

function App() {

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Body />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/logIn',
          element: <LogIn />
        },
        {
          path: '/signUp',
          element: <SignUp />
        },
        {
          path: '/forgotPassword',
          element: <ForgotPassword />
        },
        {
          path: '/resetPassword',
          element: <ResetPassword />
        },
        {
          path: '/allUsers',
          element: <UserTable />
        },
        {
          path: '/userProfile',
          element: <UserProfile />
        },
        {
          path: '/wallet',
          element: <Wallet />
        },
      ]
    },
    {
      path: "/search",
      element: <ResultsPage />,
    },
    {
      path: "/timeline/:email", // Add the timeline route here
      element: <TimelinePage />,
    }
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={appRouter} />
    </Provider>
  );
}

export default App;
